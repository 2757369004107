export const TEST_MODELS = [
  {
    id: '101',
    name: 'st_comb/st_1',
    file: 'st_1.splat',
    viewSettings: {
      camera: {
        position: [-22.08, 9.11, -7.05],
        rotation: [-3.07, -50.37, -5.01],
        fov: 75,
        near: 0.1,
        far: 1000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 200,
      maxPanY: 200,
      maxPanZ: 200,
    },
  },
  {
    id: '102',
    name: 'st_comb/st_2',
    file: 'st_2.splat',
    viewSettings: {
      camera: {
        position: [-22.08, 9.11, -7.05],
        rotation: [-3.07, -50.37, -5.01],
        fov: 75,
        near: 0.05,
        far: 1000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 200,
      maxPanY: 200,
      maxPanZ: 200,
    },
  },
  { id: '103', name: 'RCH', file: 'rch.splat' },
];
