import React from 'react';
import { Checkbox } from '@mui/material';

function AiFunctions({ aiFunctions, onAiFunctionChange }) {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-2 text-sky-600">AI Functions</h3>
      <div className="space-y-2">
        {Object.entries(aiFunctions).map(([func, isChecked]) => (
          <div key={func} className="flex items-center">
            <Checkbox
              checked={isChecked}
              onChange={() => onAiFunctionChange(func)}
              aria-label={`Toggle ${func} function`}
            />
            <label className="ml-2 capitalize">{func}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AiFunctions;
